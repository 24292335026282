<template>
<div class="Dashboard">
    <div class="flex">
        <h1>CAN Target Psoriasis <span>|</span> <span>Dashboard</span></h1>
        <v-btn class='exportButton' @click='event_exportCSV'>
            Export
        </v-btn>
    </div>
    <div class="cont_filter">
        <div class="wrapper">
            <v-card>
                <v-container fluid>
                    <v-row align="center">

                        <v-col cols="12" sm="12">
                            <v-select
                            v-model="filter_roles"
                            :items="filter_items"
                            chips
                            label="Filter Roles"
                            multiple
                            :loading="this.fetching.active"
                            class='loader_filter'
                            ></v-select>
                        </v-col>

                    </v-row>
                </v-container>
            </v-card>
        </div>
    </div>
    <Metrics v-if='ready' :metrics='metrics'/>
    <Table v-if='ready' :physicianList='physicianList'/>
</div>
</template>

<script>
// import  from '@/components/'
import Metrics from '@/components/Dashboard/Metrics'
import Table from '@/components/Dashboard/Table'

export default {
    name: 'Dashboard',
    components: {
        Metrics,
        Table
    },
    data: function(){
        return {
            filter_items: ['client', 'developer', 'staff', 'SuperAdmin', 'tester', 'user'],
            // filter_roles: ['client', 'developer', 'staff', 'SuperAdmin', 'tester', 'user'],
            filter_roles: ['user'],
            fetching: {
                active: false,
                queue: [],
                filtersForSending: []
            },
            metrics: {
                physicians: {
                    amount: 0,
                    category: 'NO. OF PHYSICIANS',
                    icon: 'mdi-doctor'
                },
                patients: {
                    amount: 0,
                    category: 'NO. OF PATIENTS',
                    icon: 'mdi-account-injury'
                },
                patientAssessments: {
                    amount: 0,
                    category: 'NO. OF VISIT 1 SUBMISSIONS',
                    icon: 'mdi-clipboard-text-multiple'

                },
                followUps: {
                    amount: 0,
                    category: 'NO. OF VISIT 2 SUBMISSIONS',
                    icon: 'mdi-clipboard-check-multiple'
                },
                followUps_id_3: {
                    amount: 0,
                    category: 'NO. OF VISIT 3 SUBMISSIONS',
                    icon: 'mdi-clipboard-check-multiple'
                }
            },
            payload: {},
            physicianList: null,
            ready: false
        }
    },
    props: {

    },
    computed: {

    },
    created: async function(){
        this.init();
    },
    methods: {
        init: async function(){
            await this.pass_dashboardMetrics_toMetrics();
            this.ready = true;
        },
        downloadCSVFile_fromCSVString: function(csvString, filename){
            if( filename == undefined
            ||  filename == null
            ||  filename.trim().length == 0 ){
                filename = `dashboardData_${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}_T${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`;
                // ex filename: 'dashboardData_$_2022-8-26_T16-27-5'
            };
            // CREATE: link HTML
            let link = `<a id='csvLink' href='${csvString}' download='${filename}.csv'>`;
            // GIVE: link HTML to body
            document.body.insertAdjacentHTML('afterend', link);
            // GET: link on body
            let csvLink = document.querySelector('#csvLink');
            // CLICK: link to download
            csvLink.click();
            // REMOVE: link element
            csvLink.remove();
        },
        create_csvString_from2dArray(table){
            return new Promise((resolve, reject)=>{

                let csvString = 'data:text/csv;charset=utf-8,'; // start string with csv file header declarations

                if( table === null || table === undefined || !table.length){
                    reject('ERROR IN create_csvString_from2dArray(table): table argument expects an array, but found null, undefined, a number, an object, or has no length.')
                };

                for(let r = 0; r < table.length; r++){ // traverse rows of the table
                    let row = table[r];

                    if( !row.length              // row array has no length
                    &&  r === table.length - 1){ // AND End of table traversal
                        resolve(csvString);
                    }
                    else
                    if( !row.length){ // row array has no length
                        // SET: newline onto csvString
                        csvString += '\n';
                    }
                    else{
                        for(let c = 0; c < row.length; c++){ // traverse cols of the row
                            let col = row[c];

                            // MODIFY: col into properly formated colString
                            let colString = `${col}`; // Ensure working with a string
                                colString = colString.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' '); // Clean text to remove multiple spaces and jumpline (which breaks csv)
                                colString = colString.replace(/"/g, '""'); // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                            // MODIFY: colString into uriEncodedString
                            let uriEncodedString = encodeURI(colString);                    // native URI encoding method
                                uriEncodedString = uriEncodedString.replaceAll('#', '%23'); // hashmarks replaced (breaks csv)
                                uriEncodedString = uriEncodedString.replaceAll("'", '%27'); // single quotes replaced (breaks csv)
                                uriEncodedString = uriEncodedString.replaceAll("_", '%5f'); // underscores replaced (breaks csv)

                            // CREATE: colStringWithSeparator
                            let colSeparator = ',';
                            let colStringWithSeparator = `${colSeparator}${uriEncodedString}`

                            // SET: colStringWithSeparator onto csvString
                            csvString += colStringWithSeparator;

                            if( r === table.length - 1 // End of table traversal
                            &&  c === row.length - 1){ // AND End of row traversal
                                resolve(csvString);
                            }
                            else
                            if( c === row.length - 1){ // End of row traversal
                                // SET: newline onto csvString
                                csvString += '\n';
                            };
                        };
                    };
                };
            });
        },
        event_exportCSV: async function(){

            // CREATE: table structure
            let table = [
                []
                ,['Table']
                ,['Metrics']
                ,[]
                ,['No. of Physicians', this.payload.physicianCount]
                ,['No. of Patients', this.payload.patientCount]
                ,['No. of Patient Assessment Submissions', this.payload.visitCount_01]
                ,['No. of Follow-Up Submissions', this.payload.visitCount_02]
                ,[]
                ,[]
                ,[]
                ,['Table']
                ,['List of Physicians']
                ,[]
                ,['Doctor Name', 'Email', 'Country', 'Province/State', 'Profile Creation Date', 'Last Login Date & Time', 'No. of Visit 1 Submissions', 'No. of Visit 2 Submissions']
            ];

            // SET: physicianList into CSV table array
            await this.set_physicianList_intoCSVTableArray(table)
            // CREATE: csv string
            let csvString = await this.create_csvString_from2dArray(table);
            // EVENT: download CSV file
            this.downloadCSVFile_fromCSVString(csvString, `dashboardData_${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}_T${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`);
        },
        pass_dashboardMetrics_toMetrics: async function(){

            return new Promise(async (resolve)=>{

                let self = this;
                let url = '/api/dashboardMetrics';
                let sendObj = {
                    filter_roles: this.filter_roles
                };

                // IF no filter roles, then zero metrics and return
                if( this.filter_roles.length === 0){

                    if( this.fetching.active === true){
                        resolve();
                        return;
                    }
                    else
                    if( this.fetching.active === false){
                        // ZERO metrics and physicianList
                        this.metrics.physicians.amount         = 0;
                        this.metrics.patients.amount           = 0;
                        this.metrics.patientAssessments.amount = 0;
                        this.metrics.followUps.amount          = 0;
                        this.physicianList                     = [];
                        resolve();
                        return;
                    };
                }
                else{

                    if( this.fetching.active === true){
                        resolve();
                        return;
                    }
                    else
                    if( this.fetching.active === false){
                        // STATE ON
                        this.fetching.active = true;

                        // SAVE filtersForSending
                        this.fetching.filtersForSending = JSON.parse(JSON.stringify(this.filter_roles)); // stringify and parse to break reference

                        self.sendRequest('POST', url, sendObj).then((response)=>{
                            // console.log(JSON.parse(JSON.stringify(response.data)), 'DATA OBJ BACK FROM pass_dashboardMetrics_toMetrics');

                            this.payload = response.data;

                            this.metrics.physicians.amount         = this.payload.physicianCount;
                            this.metrics.patients.amount           = this.payload.patientCount;
                            this.metrics.patientAssessments.amount = this.payload.visitCount_01;
                            this.metrics.followUps.amount          = this.payload.visitCount_02;
                            this.metrics.followUps_id_3.amount          = this.payload.visitCount_03;

                            this.physicianList = modify_dateTimes_toLocalTime(this.payload.physicianList);

                            // STATE OFF
                            this.fetching.active = false;

                            let answer = is_sameInArray(this.filter_roles, this.fetching.filtersForSending);
                            if( answer === false){
                                resolve();
                                this.pass_dashboardMetrics_toMetrics();
                            }
                            else{
                                resolve();
                                return;
                            };
                        });
                    };
                };
            });

            function is_sameInArray(arr_1, arr_2){
                // Arrays NOT same length
                if( arr_1.length !== arr_2.length){
                    return false;
                };
                // Loop to compare arrays
                for(let i = 0; i < arr_1.length; i++){
                    let item = arr_1[i];
                    // Fediled to find arr_1 item in arr_2
                    if( arr_2.includes(item) === false){
                        return false;
                    };
                    // Got to end of loop without returning false
                    if( i === arr_1.length - 1){ // end of loop
                        return true;
                    };
                };
            };

            function modify_dateTimes_toLocalTime(physicianList){
                for(let i = 0; i < physicianList.length; i++){
                    let obj = physicianList[i];

                    obj.loginLast = new Date(`${obj.loginLast}`);
                    obj.profileCreationDate = new Date(`${obj.profileCreationDate}`);

                    let year_login     = obj.loginLast.toLocaleDateString(undefined, {year:'numeric'});
                    let month_login    = obj.loginLast.toLocaleDateString(undefined, {month:'numeric'});
                    let day_login      = obj.loginLast.toLocaleDateString(undefined, {day:'numeric'});
                    let time_login     = obj.loginLast.toLocaleTimeString(undefined, {timeZoneName:'short'});
                        time_login     = time_login.split(' ')[0];
                    let modified_login = `${year_login}-${month_login}-${day_login} ${time_login}`;

                    let year_creation     = obj.profileCreationDate.toLocaleDateString(undefined, {year:'numeric'});
                    let month_creation    = obj.profileCreationDate.toLocaleDateString(undefined, {month:'numeric'});
                    let day_creation      = obj.profileCreationDate.toLocaleDateString(undefined, {day:'numeric'});
                    let time_creation     = obj.profileCreationDate.toLocaleTimeString(undefined, {timeZoneName:'short'});
                        time_creation     = time_creation.split(' ')[0];
                    let modified_creation = `${year_creation}-${month_creation}-${day_creation} ${time_creation}`;

                    // obj.loginLast           = modified_login;
                    // obj.profileCreationDate = modified_creation;
                    obj.loginLast           = obj.loginLast;
                    obj.profileCreationDate = obj.profileCreationDate;

                    if( i === physicianList.length - 1){ // end of loop
                        return physicianList;
                    };
                };
            };
        },
        set_physicianList_intoCSVTableArray: function(table){
            return new Promise((resolve, reject)=>{
                for(let i = 0; i < this.physicianList.length; i++){
                    let obj = this.physicianList[i];

                    let row = [
                        obj.doctorName,
                        obj.email,
                        obj.country,
                        obj.provinceState,
                        obj.profileCreationDate,
                        obj.loginLast,
                        obj.visitCount_01,
                        obj.visitCount_02,
                        obj.visitCount_03,
                    ];

                    table.push(row);

                    if( i === this.physicianList.length - 1){ // end of loop
                        resolve();
                    };
                };
            });
        }
    },
    watch: {
        filter_roles: async function(){
            this.pass_dashboardMetrics_toMetrics();
        },
    }
}
</script>

<style lang='scss'>

    .displayNone {
        display: none;
    }

    .Dashboard {
        position: relative;
        background-color: rgb(245, 246, 248);
        margin-bottom: 16px;

        .cont_filter {
            // border: 1px solid blue;

            .wrapper {
                margin: 0 12%;
                @media(max-width: 1399px){ // xl <= 1400
                    margin: 0 8%;
                }
                @media(max-width: 1199px){ // lg <= 1200
                    margin: 0 4%;
                }

                .v-card {
                    // border: 1px solid red;

                    width: calc(50% - 12px);
                    @media(max-width: 991px){ // md <= 992
                        width: 100%;
                    }
                    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

                    > .container {
                        padding: 8px 24px 1px;
                    }
                }
            }
        }

        .flex {
            // border: 1px solid blue;

            display: flex;
            position: relative;

            @media(max-width: 1399px){ // xl <= 1400

            }
            @media(max-width: 1199px){ // lg <= 1200

            }
            @media(max-width: 991px){ // md <= 992

            }
            @media(max-width: 767px){ // sm <= 768

            }
            @media(max-width: 575px){ // xs <= 576
                display: block;
                margin-bottom: 48px;
                padding-top: 1px;
            }

            h1 {
                // border: 1px solid red;

                text-align: left;
                color: $blue-dark;
                font-size: 20px;
                font-weight: 500;
                margin: 24px 0 24px 12%;


                @media(max-width: 1399px){ // xl <= 1400
                    margin: 24px 8%;
                }
                @media(max-width: 1199px){ // lg <= 1200
                    margin: 24px 4%;
                }

                span {
                    margin-left: 8px;
                    color: rgb(135,146,157);
                    font-weight: 400;

                    @media(max-width: 575px){ // xs <= 576
                        display: block;
                        margin-left: 0;
                    }
                }
                // just the '|' part
                span:first-of-type {
                    @media(max-width: 575px){ // xs <= 576
                        display: none;
                    }
                }
            } // h1

            button {
                // border: 1px solid red;

                position: absolute;
                top: 24px;
                right: 12%;
                display: inline;

                background-color: rgb(0, 59, 110) !important;
                color: white;
                text-transform: unset !important; // removes vuetify uppercase rule

                @media(max-width: 1399px){ // xl <= 1400
                    right: 8%;
                }
                @media(max-width: 1199px){ // lg <= 1200
                    right: 4%;
                }
                @media(max-width: 575px){ // xs <= 576
                    top: 80px;
                }
            }
        }
    }

</style>
