<template>
    <div class="Metrics">
        <div
        v-for="(obj, index) in metrics"
        class='card'
        :key="index"
        >
            <p class='icon'><v-icon>{{ metrics[index].icon }}</v-icon></p>
            <p class='amount'>{{ metrics[index].amount }}</p>
            <p class='category'>{{ metrics[index].category }}</p>
        </div>
    </div>
</template>

<script>
// import  from '@/components/'

export default {
    components: {

    },
    data: function(){
        return {
            ready: false
        }
    },
    props: {
        metrics: {
            type: Object,
            required: true,
            default: function(){
                return {errorMessage: "Need to pass an Object to Metrics component metrics prop."}
            }
        }
    },
    computed: {

    },
    created: async function(){
        this.init();
    },
    methods: {
        init: async function(){

            this.ready = true;
        }
    }
}
</script>

<style lang='scss'>

    .Metrics {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 24px;
        padding: 16px 12%;

        @media(max-width: 1399px){ // xl <= 1400
            padding: 16px 8%;
        }
        @media(max-width: 1199px){ // lg <= 1200
            padding: 16px 4%;
        }


        .card {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            background-color: white;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
            padding: 24px;
            min-width: 200px;

            p {
                margin: 0; // zero global margin rule on p
            }

            .icon {
                width: 50%;
                height: 56px;
                text-align: left;

                // border: 1px solid blue;

                .v-icon {
                    font-size: 40px;
                    color: $blue-medium;
                }
            }

            .amount {
                width: 50%;
                height: 56px;
                text-align: right;
                color: $primary;
                font-size: 26px;
                font-weight: 500;

                // border: 1px solid red;
            }

            .category {
                width: 100%;
                margin-top: 8px;
                text-align: right;
                color: rgb(0,59,110);
                line-height: 18px;

                // border: 1px solid blue;
            }
        }
    }
</style>
