<template>
<div class="Table">
    <div class="card">
        <!-- START Vuetify Table -->
        <v-card>
            <v-card-title>
                <span class='tableTitle'>List of Physicians</span>
                <v-text-field
                    class='searchField'
                    v-model="search"
                    label="Search and filter table"
                    single-line
                    hide-details
                    outlined
                    dense
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="rows"
                :search="search"
            ></v-data-table>
        </v-card>
        <!-- END Vuetify Table -->
    </div>
</div>
</template>

<script>
// import  from '@/components/'

export default {
    name: '',
    components: {

    },
    data: function() {
        return {
            search: '',
            headers: [
                {
                    text: 'Doctor Name',
                    value: 'doctorName',
                    align: 'center'
                },
                {
                    text: 'Email',
                    value: 'email',
                    align: 'center'
                },
                {
                    text: 'Country',
                    value: 'country',
                    align: 'center',
                },
                {
                    text: 'Province/State',
                    value: 'provinceState',
                    align: 'center',
                },
                {
                    text: 'Profile Creation Date',
                    value: 'profileCreationDate',
                    align: 'center',
                    width: '120px'
                },
                {
                    text: 'Last Login Date & Time',
                    value: 'loginLast',
                    align: 'center',
                    width: '152px'
                },
                {
                    text: 'No. of Visit 1 Submissions',
                    value: 'visitCount_01',
                    align: 'center',
                },
                {
                    text: 'No. of Visit 2 Submissions',
                    value: 'visitCount_02',
                    align: 'center',
                },
                {
                    text: 'No. of Visit 3 Submissions',
                    value: 'visitCount_03',
                    align: 'center',
                }
            ],
            rows: [

                // rows are commented out because
                // they are filled in from request data
                // that the Dashboard parent component sends down.

                // {
                //     doctorName: 'Dr Ctc Test2',
                //     email: 'ctctest21@gmail.com',
                //     province: 'Alberta',
                //     profileCreationDate: '2022-04-07',
                //     loginLast: '2022-07-19 08:03:40',
                //     visitCount_01: 14,
                //     visitCount_02: 9
                // },
                // {
                //     doctorName: 'Dr Ctc Test2',
                //     email: 'ctctest21+1@gmail.com',
                //     province: 'British Columbia',
                //     profileCreationDate: '2022-04-27',
                //     loginLast: '2022-05-03 02:42:49',
                //     visitCount_01: 4,
                //     visitCount_02: 3
                // },
                // {
                //     doctorName: 'Dr Ctc Test2',
                //     email: 'ctctest21+2@gmail.com',
                //     province: 'Ontario',
                //     profileCreationDate: '2022-04-21',
                //     loginLast: '2022-05-05 05:34:10',
                //     visitCount_01: 2,
                //     visitCount_02: 0
                // },
                // {
                //     doctorName: 'Dr Ctc Test2',
                //     email: 'ctctest21+3@gmail.com',
                //     province: 'British Columbia',
                //     profileCreationDate: '',
                //     loginLast: '2022-04-07 06:13:02',
                //     visitCount_01: 0,
                //     visitCount_02: 0
                // },
                // {
                //     doctorName: 'Dr Ctc Test1',
                //     email: 'ctctest21+5@gmail.com',
                //     province: '',
                //     profileCreationDate: '',
                //     loginLast: '2022-04-05 08:59:41',
                //     visitCount_01: 0,
                //     visitCount_02: 0
                // },
                // {
                //     doctorName: 'Dr Ctc Test2',
                //     email: 'ctctest21+4@gmail.com',
                //     province: 'Quebec',
                //     profileCreationDate: '2022-04-08',
                //     loginLast: '2022-05-11 07:18:38',
                //     visitCount_01: 1,
                //     visitCount_02: 0
                // },
                // {
                //     doctorName: 'Dr Ctc Test2',
                //     email: 'ctctest21+6@gmail.com',
                //     province: 'British Columbia',
                //     profileCreationDate: '',
                //     loginLast: '2022-04-05 09:06:55',
                //     visitCount_01: 0,
                //     visitCount_02: 0
                // },
                // {
                //     doctorName: 'Ms Ctc Test2',
                //     email: 'ctctest21+7@gmail.com',
                //     province: '',
                //     profileCreationDate: '',
                //     loginLast: '2022-04-06 09:04:16',
                //     visitCount_01: 0,
                //     visitCount_02: 0
                // },
                // {
                //     doctorName: 'Mrs. Ctc Test2',
                //     email: 'ctctest21+8@gmail.com',
                //     province: '',
                //     profileCreationDate: '',
                //     loginLast: '2022-04-07 02:53:23',
                //     visitCount_01: 0,
                //     visitCount_02: 0
                // },
                // {
                //     doctorName: 'Dr Test Test',
                //     email: 'maneja@ctccomm.com',
                //     province: 'Ontario',
                //     profileCreationDate: '2022-04-12',
                //     loginLast: '2022-06-09 1-:48:42',
                //     visitCount_01: 13,
                //     visitCount_02: 9
                // }
            ],

            ready: false
        }
    },
    props: {
        physicianList: {
            type: Array,
            required: true,
            default: function(){
                return ['Need to pass an Array to Table component for physicianList prop.']
            }
        }
    },
    computed: {

    },
    created: async function() {
        this.rows = this.physicianList;
        this.ready = true;
    },
    methods: {

    },
    watch: {
        physicianList: function(){
            this.rows = this.physicianList;
        }
    }
}
</script>

<style lang="scss">

    .Table {
        // border: 1px solid blue;

        position: relative;
        background-color: white;

        .card {
            // border: 1px solid red;

            padding: 0 12%;

            @media(max-width: 1399px){ // xl <= 1400
                padding: 0 8%;
            }
            @media(max-width: 1199px){ // lg <= 1200
                padding: 0 4%;
            }

            .v-card {
                box-shadow: none;

                .v-card__title {
                    // border: 1px solid green;

                    display: flex;
                    padding-left: 0;
                    padding-right: 0;

                    .tableTitle {
                        // border: 1px solid blue;

                        color: $blue-dark;
                        font-size: 24px;
                        text-align: left;
                        width: 768%;
                        max-width: 68%;
                    }

                    .searchField {
                        // padding: 0 8px;
                        font-weight: normal;
                        border-radius: 4px;

                        fieldset {
                            border-color: $primary;
                        }
                    }

                }
            }

            table {
                // border: 1px solid green;

                border-spacing: 0 2px;

                thead {
                    background-color: rgb(219,235,242);

                    tr {

                        th {
                            // border: 1px solid red;

                            color: $primary !important;
                            font-size: 15px;
                            border-color: white;
                            border-style: solid;
                            border-width: 0 2px 0 0 !important; // all border right
                            padding: 16px 8px 8px;

                            span {
                                display: block;
                                text-align: center;
                            }

                            i {
                                display: block;
                            }
                        }
                        th:first-of-type {
                            border-radius: 4px 0 0 4px;
                        }
                        th:last-of-type {
                            border-radius: 0 4px 4px 0;
                            border-right-width: 0 !important; // cancel border right
                        }
                    }
                }

                tbody {

                    /*
                    Vuetify table turns rows into cards on mobile (< 600px),
                    but doesn't have a border or space between cards, so hard to distinguish between them.
                    This rule adds white lines between the cards on mobile.
                    */
                    tr.v-data-table__mobile-table-row {
                        border-bottom: 8px solid white;
                    }

                    tr {
                        background-color: rgb(243,248,251);

                        td {
                            border: none !important;
                            padding: 8px 16px;

                            &:nth-of-type(5),
                            &:nth-of-type(6) {
                                font-size: 11px;
                            }

                            /*
                            On mobile (max-width: 599px), vuetify turns tables rows into cards.
                            Doing so, it takes the headers (ex: Doctor Name) and the cells for the column,
                            and combines them into one tbody td with two divs side-by-side in the card.

                            This targets the first side-by-side div (for headers) in new tbody td.
                            */
                            .v-data-table__mobile-row__header {
                                text-align: left;
                            }
                        }
                        td:first-of-type {
                            border-radius: 4px 0 0 4px;
                        }
                        td:last-of-type {
                            border-radius: 0 4px 4px 0;
                        }
                    }
                }
            }
        }
    }

</style>
